<template>
  <div class="row w-100 m-0 text-start">
    <div class="col-12">
      <h2>Users</h2>
    </div>
    <div class="col-12">
      <button class="btn btn-info btn-sm" @click="showCreate = true"><i class="fas fa-plus me-2"></i>New User</button>
    </div>
    <div class="col-12 mt-4">
      <DataTable
        :value="users"
        responsiveLayout="scroll"
        :paginator="true"
        :alwaysShowPaginator="false"
        :rows="numRows"
        :resizableColumns="true"
        columnResizeMode="expand"
        removableSort
        v-model:filters="filters"
        :loading="loading"
        ref="dt"
        dataKey="id"
        editMode="row"
        v-model:editingRows="editingRows"
        @row-edit-save="onRowEditSave"
        @row-edit-init="onRowEditInit"
      >
        <template #header>
          <div class="row justify-content-between">
            <div class="col-3">
              <div class="d-flex align-items-center">
                <p class="text-start m-0 me-3">Show</p>
                <select class="form-select" v-model="numRows" style="width: 80px">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <input type="text" class="form-control" placeholder="Search" v-model="filters['global'].value" />
            </div>
          </div>
        </template>

        <Column field="name" header="Name" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="last_name" header="Last Name" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="email" header="Email" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="phone" header="Phone" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="ld_user_id" header="LD User ID" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="team" header="Team" :sortable="true" sortField="team.name" filterField="team.name">
          <template #editor="{ data }">
            <select class="form-select" v-model="data.team_id">
              <option v-for="team in teams" :value="team.id" :key="team.id">{{ team.name }}</option>
            </select>
          </template>
          <template #body="{ data }">
            {{ data.team?.name }}
          </template>
        </Column>

        <Column header="System Roles">
          <template #editor="{ data }">
            <RoleSelector :options="roles.roles" :values="data.roles_id" @update:roles="(r) => (data.roles_id = r)" />
          </template>
          <template #body="{ data }">
            <p v-for="role in data.roles" :key="role.id" class="m-0">{{ role.name }}</p>
          </template>
        </Column>

        <Column header="Team Roles">
          <template #editor="{ data }">
            <RoleSelector
              :options="roles.team_roles"
              :values="data.team_roles_id"
              @update:roles="(r) => (data.team_roles_id = r)"
            />
          </template>
          <template #body="{ data }">
            <p v-for="role in data.team_roles" :key="role.id" class="m-0">{{ role.name }}</p>
          </template>
        </Column>

        <Column :rowEditor="true"></Column>
        <Column>
          <template #body="{ data }">
            <button class="p-row-editor-init p-link" @click="deleteUser(data)"><i class="pi pi-trash"></i></button>
          </template>
        </Column>
        <Column>
          <template #body="{ data }">
            <button class="p-row-editor-init p-link" @click="changePassword(data)"><i class="pi pi-key"></i></button>
          </template>
        </Column>
        <template #footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="exportCSV">Export CSV</button>
          </div>
        </template>
        <template #loading> <p class="text-center">Loading records, please wait...</p> </template>
      </DataTable>
    </div>
  </div>

  <CreateUserModal :showModal="showCreate" @close="showCreate = false" />
  <ResetPasswordModal :showModal="showReset" @close="showReset = false" :user="user"/>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'

import Swal from 'sweetalert2/src/sweetalert2'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useToast } from 'primevue/usetoast'

import CreateUserModal from './components/CreateUserModal.vue'
import ResetPasswordModal from './components/ResetPasswordModal.vue'
import RoleSelector from './components/RoleSelector.vue'
import { useDatatable } from '../../hooks/useDatatable'

import AuthService from '../../services/auth.service'
import TeamService from '../../services/team.service'

export default {
  components: {
    CreateUserModal,
    ResetPasswordModal,
    DataTable,
    Column,
    RoleSelector,
  },
  setup() {
    const store = useStore()
    const users = computed(() => store.state.users.users)
    const user = ref({})
    const showCreate = ref(false)
    const showReset = ref(false)
    const showUpdate = ref(false)
    const roles = ref({})
    const teams = ref([])
    const { loading, filters, numRows, dt, exportCSV, editingRows } = useDatatable()
    const toast = useToast()

    onMounted(() => {
      fetchUsers()
    })

    const fetchUsers = async () => {
      loading.value = true
      const users = await AuthService.fetchUsers()
      roles.value = await AuthService.fetchRoles()
      teams.value = await TeamService.fetchAll()
      store.commit('users/SET_USERS', users)
      loading.value = false
    }

    const deleteUser = async (user) => {
      Swal.fire({
        title: `Are you sure to eliminate ${user.name}?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          AuthService.deleteUser(user.id)
          store.commit('users/DELETE_USER', user.id)
          Swal.fire('Deleted!', 'User has been deleted.', 'success')
        }
      })
    }

    const onRowEditSave = async (event) => {
      let { newData } = event
      const user = { ...newData, roles: newData.roles_id, team_roles: newData.team_roles_id }
      try {
        const updateUser = await AuthService.updateUser(user.id, user)
        store.commit('users/UPDATE_USER', updateUser)
        store.dispatch('')
      } catch (error) {
        console.log(error)
        if (error.errors?.email) {
          toast.add({ severity: 'error', summary: 'Error', detail: error.errors.email[0], life: 3000 })
        }
      }
    }

    const onRowEditInit = (event) => {
      const { data } = event
      data.roles_id = getRoleIds(data.roles)
      data.team_roles_id = getRoleIds(data.team_roles)
    }

    const getRoleIds = (roles) => {
      return roles.map((r) => r.id)
    }

    const changePassword = (data) => {
      user.value = data
      showReset.value = true
    }

    return {
      users,
      deleteUser,
      user,
      showCreate,
      showUpdate,
      showReset,
      numRows,
      filters,
      loading,
      dt,
      exportCSV,
      editingRows,
      onRowEditSave,
      onRowEditInit,
      getRoleIds,
      changePassword,
      roles,
      teams,
    }
  },
}
</script>
