<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    icon="pi pi-external-link"
    position="center"
    :style="{ width: '40vw' }"
    @hide="$emit('close')"
    header="New User"
  >
    <form class="row" @submit.prevent="() => {}">
      <div class="col-6">
        <div class="form-group">
          <label for="">First Name</label>
          <input type="text" class="form-control" :class="v.name.$error ? 'is-invalid' : ''" v-model="v.name.$model" />
          <p class="invalid-feedback d-block m-0">{{ v.name.$errors[0]?.$message }}</p>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Last Name</label>
          <input
            type="text"
            class="form-control"
            :class="v.last_name.$error ? 'is-invalid' : ''"
            v-model="v.last_name.$model"
          />
          <p class="invalid-feedback d-block m-0">{{ v.last_name.$errors[0]?.$message }}</p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="">Email</label>
          <input
            type="email"
            class="form-control"
            :class="v.email.$error ? 'is-invalid' : ''"
            v-model="v.email.$model"
          />
          <p class="invalid-feedback d-block m-0">{{ v.email.$errors[0]?.$message }}</p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="">Phone</label>
          <input type="tel" class="form-control" :class="v.phone.$error ? 'is-invalid' : ''" v-model="v.phone.$model" />
          <p class="invalid-feedback d-block m-0">{{ v.phone.$errors[0]?.$message }}</p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="">Password</label>
          <input
            type="password"
            class="form-control"
            :class="v.password.$error ? 'is-invalid' : ''"
            v-model="v.password.$model"
          />
          <p class="invalid-feedback d-block m-0">{{ v.password.$errors[0]?.$message }}</p>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Confirm Password</label>
          <input
            type="password"
            class="form-control"
            :class="v.password_confirmation.$error ? 'is-invalid' : ''"
            v-model="v.password_confirmation.$model"
          />
          <p class="invalid-feedback d-block m-0">{{ v.password_confirmation.$errors[0]?.$message }}</p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="">LD User ID</label>
          <input
            type="text"
            class="form-control"
            :class="v.ld_user_id.$error ? 'is-invalid' : ''"
            v-model="v.ld_user_id.$model"
          />
          <p class="invalid-feedback d-block m-0">{{ v.ld_user_id.$errors[0]?.$message }}</p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="">Team</label>
          <select class="form-select" :class="v.team_id.$error ? 'is-invalid' : ''" v-model="v.team_id.$model">
            <option selected disabled :value="0">--Select--</option>
            <option v-for="team in state.teams" :value="team.id" :key="team.id">{{ team.name }}</option>
          </select>
          <p class="invalid-feedback d-block m-0">{{ v.team_id.$errors[0]?.$message }}</p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="" class="mb-1">System Roles</label>
          <RoleSelector :options="state.roles.roles" :values="state.selectedSystemRoles" @update:roles="updateRoles" />
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="" class="mb-1">Team Roles</label>
          <RoleSelector
            :options="state.roles.team_roles"
            :values="state.selectedTeamRoles"
            @update:roles="updateTeamRoles"
          />
        </div>
      </div>
    </form>

    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('close')">Close</button>
      <button type="submit" class="btn btn-primary" @click="handleSubmit">Save</button>
    </template>
  </Dialog>
</template>

<script>
import { computed, onMounted, reactive, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'

import { required, email, helpers, sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import Dialog from 'primevue/dialog'
import RoleSelector from './RoleSelector.vue'
import { useToast } from 'primevue/usetoast'

import AuthService from '../../../services/auth.service'
import TeamService from '../../../services/team.service'

export default {
  components: { Dialog, RoleSelector },
  props: {
    showModal: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const store = useStore()
    const toast = useToast()
    const state = reactive({
      roles: {},
      teams: [],
      selectedTeamRoles: [],
      selectedSystemRoles: [],
    })

    const form = reactive({
      name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      ld_user_id: '',
      team_id: 0,
    })

    onMounted(() => {
      fetchRoles()
      fetchTeams()
    })

    // Fetching Data
    const fetchRoles = async () => {
      const resp = await AuthService.fetchRoles()
      state.roles = resp
    }

    const fetchTeams = async () => {
      const resp = await TeamService.fetchAll()
      state.teams = resp
    }

    // Role Selector
    const updateRoles = (roles) => {
      state.selectedSystemRoles = roles
    }

    // Team Role selector
    const updateTeamRoles = (roles) => {
      state.selectedTeamRoles = roles
    }

    // Validation
    const rules = computed(() => ({
      name: { required },
      last_name: { required },
      email: { required, email },
      phone: { required },
      password: { required },
      password_confirmation: {
        required,
        sameAs: helpers.withMessage('Password does not match', sameAs(form.password)),
      },
      ld_user_id: { required },
      team_id: { selected: helpers.withMessage('Team is required', (value) => value !== 0) },
    }))

    const v = useVuelidate(rules, form)

    const reset = () => {
      state.selectedTeamRoles = []
      state.selectedSystemRoles = []
      form.name = ''
      form.last_name = ''
      form.email = ''
      form.phone = ''
      form.password = ''
      form.password_confirmation = ''
      form.ld_user_id = ''
      form.team_id = 0
      v.value.$reset()
    }

    const handleSubmit = async () => {
      const isValid = await v.value.$validate()
      if (!isValid) return

      const user = {
        ...form,
        roles: state.selectedSystemRoles,
        team_roles: state.selectedTeamRoles,
      }
      try {
        const newUser = await AuthService.registerUser(user)
        store.commit('users/ADD_USER', newUser)
        reset()
        emit('close')
        toast.add({ severity: 'success', summary: 'User added with success', detail: '', life: 3000 })
      } catch (error) {
        if (error.errors?.email) {
          toast.add({ severity: 'error', summary: 'Error', detail: error.errors.email[0], life: 3000 })
        }
      }
    }

    return {
      handleSubmit,
      state,
      reset,
      updateRoles,
      updateTeamRoles,
      form,
      v,
    }
  },
}
</script>
