<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    icon="pi pi-external-link"
    position="center"
    :style="{ width: '40vw' }"
    @hide="$emit('close')"
    header="Reset Password"
  >
    <form class="row" @submit.prevent="() => {}">
 
      <div class="col-12 pb-3">
          <h5>Current User: <b>{{ user.name ? user.name + ' ' + user.last_name : ''}}</b></h5>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">New Password</label>
          <input
            type="password"
            class="form-control"
            :class="v.password.$error ? 'is-invalid' : ''"
            v-model="v.password.$model"
          />
          <p class="invalid-feedback d-block m-0">{{ v.password.$errors[0]?.$message }}</p>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Confirm New Password</label>
          <input
            type="password"
            class="form-control"
            :class="v.password_confirmation.$error ? 'is-invalid' : ''"
            v-model="v.password_confirmation.$model"
          />
          <p class="invalid-feedback d-block m-0">{{ v.password_confirmation.$errors[0]?.$message }}</p>
        </div>
      </div>
    </form>

    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('close')">Close</button>
      <button type="submit" class="btn btn-primary" @click="handleSubmit">Save</button>
    </template>
  </Dialog>
</template>

<script>
import { computed, onMounted, reactive, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'

import { required, email, helpers, sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import Dialog from 'primevue/dialog'
import RoleSelector from './RoleSelector.vue'
import { useToast } from 'primevue/usetoast'

import AuthService from '../../../services/auth.service'
import TeamService from '../../../services/team.service'

export default {
  components: { Dialog, RoleSelector },
  props: {
    showModal: { type: Boolean, default: false },
    user: { type: Object, default: false },
  },
  setup(props, { emit }) {
    const store = useStore()
    const toast = useToast()

    const form = reactive({
      password: '',
      password_confirmation: '',
    })

    onMounted(() => {
    })

    // Validation
    const rules = computed(() => ({
      password: { required },
      password_confirmation: {
        required,
        sameAs: helpers.withMessage('Password does not match', sameAs(form.password)),
      }
    }))

    const v = useVuelidate(rules, form, { $scope: false })

    const reset = () => {
      form.password = ''
      form.password_confirmation = ''
      v.value.$reset()
    }

    const handleSubmit = async () => {
      const isValid = await v.value.$validate()
      if (!isValid) return

      const data = {
        ...form
      }
      try {
        const user = await AuthService.adminResetPassword(props.user.id, data)
        reset()
        emit('close')
        toast.add({ severity: 'success', summary: 'Password updated with success', detail: '', life: 3000 })
      } catch (error) {
        if (error.errors?.email) {
          toast.add({ severity: 'error', summary: 'Error', detail: error.errors.email[0], life: 3000 })
        }
      }
    }

    return {
      handleSubmit,
      reset,
      form,
      v,
    }
  },
}
</script>
