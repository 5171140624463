<template>
  <ul class="mb-0 list-group">
    <li v-for="role in selectedRoles" :key="role.id" class="list-group-item">
      {{ role.name }}
      <i class="fas fa-close" style="color: red; cursor: pointer" @click="deleteRole(role.id)"></i>
    </li>
  </ul>

  <div v-if="newRole" class="d-flex align-items-center mb-1 w-100">
    <select class="form-select" aria-label="Default select example" v-model="selectedRoleId" @change="addRole">
      <option selected disabled :value="0">--Select--</option>
      <option v-for="role in options" :value="role.id" :key="role.id">{{ role.name }}</option>
    </select>

    <button class="btn btn-danger btn-sm ms-2" @click="closeRoleSelector">
      <i class="fas fa-close"></i>
    </button>
  </div>

  <button class="btn btn-primary btn-sm mt-1" @click="newRole = true">
    <i class="fas fa-add"></i>
  </button>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
export default {
  props: {
    options: { type: Array, required: true },
    values: { type: Array, required: true },
  },
  setup(props, { emit }) {
    const selectedRoleId = ref(0)
    const newRole = ref(false)
    const roles = ref(props.values)

    const closeRoleSelector = () => {
      selectedRoleId.value = 0
      newRole.value = false
    }

    const addRole = () => {
      if (selectedRoleId.value == 0 || roles.value.find((r) => r == selectedRoleId.value) != undefined)
        return closeRoleSelector()
      roles.value.push(selectedRoleId.value)
      closeRoleSelector()
      emit('update:roles', roles.value)
    }

    const deleteRole = (role_id) => {
      roles.value = roles.value.filter((id) => id != role_id)
      emit('update:roles', roles.value)
    }

    const selectedRoles = computed(() => roles.value.map((id) => props.options.find((role) => role.id == id)))

    return { selectedRoleId, roles, newRole, closeRoleSelector, addRole, deleteRole, selectedRoles }
  },
}
</script>
